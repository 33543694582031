import { graphql, useStaticQuery, Link } from "gatsby"
import * as React from "react"
import slugify from "@sindresorhus/slugify"

// Importing styles
import "./styles/navigation.scss"

// Importing icons
import { FaChevronLeft, FaChevronRight } from "react-icons/fa"

// Importing Bootstrap components
import { Container, Row } from "react-bootstrap"

export function Navigation() {
  const {
    allShopifyCollection: { edges },
  } = useStaticQuery(graphql`
    query {
      allShopifyCollection(sort: { fields: title }) {
        edges {
          node {
            handle
            title
          }
        }
      }
    }
  `)

  return (
    <nav>
      <Container>
        <div className="chevron">
          <FaChevronLeft />
        </div>
        <ul>
          <li>
            <Link
              key="All"
              to="/products/"
              activeClassName="category-nav-active"
            >
              All products
            </Link>
          </li>
          {edges.map((collection) => (
            <li>
              <Link
                key={collection.node.id}
                to={`/collections/${collection.node.handle}`}
                activeClassName="category-nav-active"
              >
                {collection.node.title}
              </Link>
            </li>
          ))}
        </ul>
        <div className="chevron">
          <FaChevronRight />
        </div>
      </Container>
    </nav>
  )
}
