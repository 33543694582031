import * as React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { StaticImage } from "gatsby-plugin-image"
import { formatPrice } from "../utils/format-price"

// importing layout
import { Layout } from "../layouts/layout"

// Importing styles
import "./styles/collection.scss"

// Importing Bootstrap components
import { Button, Card, Col, Container, Row } from "react-bootstrap"

const Post = ({ data, pageContext }) => {
  const { shopifyCollection } = data
  const { previous, next } = pageContext
  return (
    <Layout>
      <div className="collection-template">
        <Container>
          <Row className="intro">
            <h1 className="shout">{shopifyCollection.title}</h1>
          </Row>
          <Row className="fade-in-fast">
            {shopifyCollection.products.map((product) => {
              return (
                <Col
                  className="product-card"
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  xl={4}
                >
                  <Card className="card">
                    <Link
                      to={`/products/${product.handle}`}
                      aria-label={`View ${product.title} product page`}
                    >
                      <GatsbyImage
                        alt={product.featuredImage.altText}
                        image={product.featuredImage.gatsbyImageData}
                      />
                      <div className="price shout">
                        {formatPrice(
                          product.priceRangeV2.minVariantPrice.currencyCode,
                          product.priceRangeV2.minVariantPrice.amount
                        )}
                      </div>
                      <Card.Body>
                        <h2 className="talk">{product.title}</h2>
                        <div className="cta talk">View Product &rarr;</div>
                      </Card.Body>
                    </Link>
                  </Card>
                </Col>
              )
            })}
          </Row>
        </Container>
      </div>
    </Layout>
  )
}

export default Post

export const query = graphql`
  query ShopfiyCollectionById($slug: String!) {
    shopifyCollection(handle: { eq: $slug }) {
      id
      products {
        featuredImage {
          gatsbyImageData
          altText
        }
        handle
        id
        priceRangeV2 {
          minVariantPrice {
            amount
            currencyCode
          }
        }
        title
      }
      title
    }
  }
`
